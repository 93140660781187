
import { MenuItem } from "@/types";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Auth = namespace("auth");

@Component({
  props: {
    isOpen: {
      type: Boolean,
      default: true
    }
  }
})
export default class Default extends Vue {
  items: any = [
    { title: "Home", icon: "mdi-home", pageName: "CourseEvents", exact: true },
    { title: "Companies", icon: "mdi-domain", pageName: "CompaniesList" },
    {
      title: "Delegates",
      icon: "mdi-account-multiple",
      pageName: "DelegatesList"
    },
    {
      title: "Courses",
      icon: "mdi-school",
      pageName: "CoursesList"
    },
    {
      title: "Online Courses",
      icon: "mdi-cast-education",
      pageName: "OnlineCoursesList"
    },
    {
      title: "Audits",
      icon: "mdi-calendar-month",
      pageName: "AuditsList"
    },
    {
      title: "Certificates Manager",
      icon: "mdi-seal",
      pageName: "Certificate"
    },
    {
      title: "Instructors",
      icon: "mdi-account-box",
      pageName: "Instructors"
    },
    {
      title: "Chat",
      icon: "mdi-chat-outline",
      pageName: "Chat"
    },
    {
      title: "Quotes",
      icon: "mdi-clipboard-edit-outline",
      pageName: "Quotes"
    }
    /*{ title: "Settings", icon: "mdi-cog", pageName: "CourseEvents" },
    { title: "Log out", icon: "mdi-help-box", pageName: "logout" }*/
  ];
  reportsMenu: Array<MenuItem> = [
    { title: "DASHBOARD", icon: "mdi-signal-cellular-3", pageName: "Reports" },
    { title: "EARNINGS", icon: "mdi-piggy-bank-outline", pageName: "Earnings" },
    { title: "TARGETS", icon: "mdi-check-circle", pageName: "Targets" },
    {
      title: "PROGRESS TRACKER",
      icon: "mdi-chart-pie",
      pageName: "CoursesProgress"
    }
  ];
  settingsMenu: Array<MenuItem> = [
    { title: "USER ROLES", icon: "mdi-account-lock", pageName: "UserRoles" },
    { title: "USERS", icon: "mdi-account-multiple", pageName: "Users" },
    { title: "Company Info", icon: "mdi-border-color", pageName: "CompanyInfo" }
  ];

  @Auth.Action("authLogout")
  authLogout!: () => Promise<void>;

  get isMobile() {
    return !this.$vuetify.breakpoint.mdAndUp;
  }

  set drawer(val: boolean) {
    this.$emit("toggle", val);
  }
  get drawer() {
    return this.$props.isOpen;
  }

  controller(pageName: string): void {
    if (pageName) {
      if (pageName === "logout") {
        this.authLogout().then(() => {
          location.reload();
        });
      } else {
        if (pageName !== this.$route.name) {
          this.$router.push({ name: pageName });
        }
      }
    }
  }
}
