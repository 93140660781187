
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "HeaderSearch"
})
export default class HeaderSearch extends Vue {
  isLoading = false;
  items: [] = [];
  model: any = null;
  search: any = null;
  tab: any = null;

  @Watch("search")
  onSearchChanged() {
    if (this.items.length > 0) return;
    this.isLoading = true;

    this.axios.get("https://api.coingecko.com/api/v3/coins/list");
  }
}
