
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ScrollToTopBtn extends Vue {
  show = false;
  container: Element | null = null;

  //   const body = document.querySelector("body");
  //   return body
  //     ? Math.abs(body.scrollHeight - body.clientHeight - body.scrollTop) > 100
  //     : false;
  // }

  scrollHandler() {
    this.show = this.container ? this.container.scrollTop > 1000 : false;
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  created() {
    window.addEventListener("scroll", this.scrollHandler);
    this.container = document.querySelector("html");
  }
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandler);
  }
}
