
import { Component, Emit, Vue } from "vue-property-decorator";
import HeaderSearch from "./HeaderSearch.vue";

@Component({
  name: "TheHeader",
  components: { HeaderSearch }
})
export default class TheHeader extends Vue {
  @Emit("handleDrawer")
  drawerHandle() {
    this.$emit("handleDrawer");
  }
}
