
import ScrollToTopBtn from "@/components/common/ScrollToTopBtn.vue";
import TheHeader from "@/components/layout/TheHeader.vue";
import TheSidebar from "@/components/layout/TheSidebar.vue";
import { ActionButton } from "@/types";
import { Component, Vue } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
const namespace = "message";

@Component({
  name: "default",
  components: { TheHeader, TheSidebar, ScrollToTopBtn }
})
export default class Default extends Vue {
  isOpen = false;
  timeout = 4000;
  buttons: Array<ActionButton> = [
    { text: "Back", icon: "mdi-chevron-left", activeClass: "v-white" },
    {
      text: "Delete",
      icon: "mdi-delete",
      activeClass: "v-gray icon-reverse"
    },
    {
      text: "Save",
      icon: "mdi-content-save",
      activeClass: "v-dark icon-reverse"
    }
  ];

  @Getter("getFlushError", { namespace })
  getFlushError!: any;

  @Getter("getFlushSuccess", { namespace })
  getFlushSuccess!: any;

  get success(): boolean {
    return !!this.getFlushSuccess;
  }
  set success(newValue: boolean) {
    this.setFlushSuccess(newValue);
  }

  get error(): boolean {
    return !!this.getFlushError;
  }
  set error(newValue: boolean) {
    this.setFlushError(newValue);
  }
  @Mutation("setFlushError", { namespace })
  setFlushError: any;

  @Mutation("setFlushSuccess", { namespace })
  setFlushSuccess: any;

  changeMenuFlag(data: boolean) {
    this.isOpen = data;
  }
  closeSideMenu() {
    this.isOpen = !this.isOpen;
  }
}
